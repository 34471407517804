import TictactoeBoard from "../../../../src/components/tictactoe/board";
import { Quote, CaptionImage, Note, SeeOnGithub } from "../../../../src/components/literary-components.js";
import * as React from 'react';
export default {
  TictactoeBoard,
  Quote,
  CaptionImage,
  Note,
  SeeOnGithub,
  React
};