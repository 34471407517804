// extracted by mini-css-extract-plugin
export var AppHeader = "tictactoe-module--App-header--69d59";
export var board = "tictactoe-module--board--f8d10";
export var lastBoardRow = "tictactoe-module--last-board-row--1ef54";
export var noBottomMargin = "tictactoe-module--no-bottom-margin--e060f";
export var noRightMargin = "tictactoe-module--no-right-margin--05f08";
export var outerBoard = "tictactoe-module--outer-board--85cd7";
export var playOButton = "tictactoe-module--play-o-button--b0d10";
export var playXButton = "tictactoe-module--play-x-button--e9336";
export var right = "tictactoe-module--right--037ce";
export var square = "tictactoe-module--square--6e7bc";