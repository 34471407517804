import * as React from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {faLink} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGithub} from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';


export function Quote({author, children}) {
  const authorElement = author ? <p className="author-quote">{author}</p> : null;
  return (
    <div className="quote">
      <blockquote>
        {children}
      </blockquote>
      {authorElement}
    </div>
  );
};

Quote.propTypes = {
  author: PropTypes.string,
  children: PropTypes.node.isRequired,
};


export function CaptionImage({source, src, caption}) {
  const sourceElement = source ? <a href={source}>Source</a> : null;
  return (
    <div className="image-container">
      <GatsbyImage image={getImage(src)} alt={caption} />
      <div className="image-caption">{caption}</div>
      {sourceElement}
    </div>
  );
}

CaptionImage.propTypes = {
  source: PropTypes.string,
  src: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};


export function H2({children}) {
  const id = children.replaceAll(' ', '-');
  return (
    <h2 id={id}>
      {children}
      {' '}
      <a className="link-icon" href={`#${id}`}>{<FontAwesomeIcon icon={faLink} />}</a>
    </h2>

  );
}

H2.propTypes = {
  children: PropTypes.string.isRequired,
};


export function Note({children}) {
  return (
    <div className="note-background">
      <div className="note-content">
        {children}
      </div>
    </div>
  );
}

Note.propTypes = {
  children: PropTypes.node.isRequired,
};


export function SeeOnGithub({link}) {
  return (
    <div className="see-on-github">
      <a className='social-icon' href={link}>{<FontAwesomeIcon icon={faGithub} />}</a>
    </div>
  );
}

SeeOnGithub.propTypes = {
  link: PropTypes.string.isRequired,
};


export function Table({children}) {
  return (
    <div className='table-container'>
      {children}
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
};


export function LocalVideo({src, caption}) {
  const captionComponent = caption ? <div className="image-caption">{caption}</div> : null;
  return (
    <div className="video-container">
      <video autoPlay loop>
        <source src={src} type="video/mp4" />
      </video>
      {captionComponent}
    </div>
  );
}

LocalVideo.propTypes = {
  src: PropTypes.string.isRequired,
  caption: PropTypes.string,
};
